import React, { Component } from 'react'
import ExperienceThisLogo from 'assets/ettv-logo.png'

export default class ExperienceThisDescription extends Component {
  render() {
    return (
      <div>
        <img src={ExperienceThisLogo} />
      </div>
    )
  }
}
