import React, { Component } from 'react';
import Video from 'components/video'
import Dot from 'components/dot';
import './hero.css';
import PlaylistNav from "components/playlistNav"
import HOYCalendar from "assets/101010-house-of-yes-calendar.mp4";
import HOYParty from "assets/101010-house-of-yes-dance-party.mp4";
import HOYCompetition from "assets/101010-house-of-yes-drag-competition.mp4";
import HOYParadise from "assets/101010-house-of-yes-paradise.mp4";
import HOYUnicorn from "assets/101010-house-of-yes-unicorn.mp4";
import HaywireBali from "assets/10102020-haywire-weekend-bali.mp4";
import HaywireHydrate from "assets/10102020-haywire-weekend-hydrate.mp4";
import HaywireSki from "assets/10102020-haywire-weekend-ski.mp4";
import HaywireTeaser from "assets/10102020-haywire-weekend-teaser.mp4";
import HaywireTV from "assets/10102020-haywire-weekend-tv.mp4";

import HOYVideo1 from "assets/video-1.mp4";
import HaywireVideo2 from "assets/video-2.mp4";
import HaywireVideo3 from "assets/video-3.mp4";
import HaywireVideo4 from "assets/video-4.mp4";

const HOUSE_OF_YES = [
  {
    id: 0,
    title: "Dance Party",
    src: HOYParty,
    duration: 48,
    durationPretty: "00:48"
  },
  {
    id: 1,
    title: "Salsa & Latin Night",
    src: HOYVideo1,
    duration: 60,
    durationPretty: "1:00"
  },
  {
    id: 2,
    title: "Drag Competition",
    src: HOYCompetition,
    duration: 15,
    durationPretty: "00:15"
  },
  {
    id: 3,
    title: "Paradise",
    src: HOYParadise,
    duration: 60,
    durationPretty: "1:00"
  },
  {
    id: 4,
    title: "Unicorn",
    src: HOYUnicorn,
    duration: 59,
    durationPretty: "00:59"
  }
];

const HAYWIRE = [
  {
    id: 0,
    title: "Bali",
    src: HaywireVideo2,
    duration: 58,
    durationPretty: "00:58"
  },
  {
    id: 1,
    title: "Teaser",
    src: HaywireVideo4,
    duration: 34,
    durationPretty: "00:34"
  },

  {
    id: 2,
    title: "Ski",
    src: HaywireVideo3,
    duration: 49,
    durationPretty: "00:49"
  },
  {
    id: 3,
    title: "Hydrate",
    src: HaywireHydrate,
    duration: 27,
    durationPretty: "00:27"
  },
  {
    id: 4,
    title: "TV",
    src: HaywireTV,
    duration: 32,
    durationPretty: "00:32"
  }
];

const PLAYLIST_1 = [
  {
    id: 0,
    title: "Playlist 1 A",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-passion-of-an-artist/the-passion-of-an-artist.m3u8"
  },
  {
    id: 1,
    title: "Playlist 1 B",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-rebirth-of-an-artist/the-rebirth-of-an-artist.m3u8",
  },
  {
    id: 2,
    title: "Playlist 1 C",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/liquorstorebabies/liquorstorebabies.m3u8",
  },
  {
    id: 3,
    title: "Playlist 1 D",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/seeyousoon/seeyousoon.m3u8",
  },
  {
    id: 4,
    title: "Playlist 1 E",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/erescubana/EresCubana-LaurenMiller.m3u8",
  }
];

const PLAYLIST_2 = [
  {
    id: 0,
    title: "Playlist 2 A",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-rebirth-of-an-artist/the-rebirth-of-an-artist.m3u8",
  },
  {
    id: 1,
    title: "Playlist 2 B",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-passion-of-an-artist/the-passion-of-an-artist.m3u8"
  },
  {
    id: 2,
    title: "Playlist 2 C",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/liquorstorebabies/liquorstorebabies.m3u8",
  }
];

const PLAYLIST_3 = [
  {
    id: 0,
    title: "Playlist 3 A",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/liquorstorebabies/liquorstorebabies.m3u8",
  },
  {
    id: 1,
    title: "Playlist 3 B",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-rebirth-of-an-artist/the-rebirth-of-an-artist.m3u8",
  },
  {
    id: 2,
    title: "Playlist 3 C",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/liquorstorebabies/liquorstorebabies.m3u8",
  },
  {
    id: 3,
    title: "Playlist 3 D",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-passion-of-an-artist/the-passion-of-an-artist.m3u8"
  },
  {
    id: 4,
    title: "Playlist 3 E",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-rebirth-of-an-artist/the-rebirth-of-an-artist.m3u8",
  },
  {
    id: 5,
    title: "Playlist 3 F",
    src: "https://s3.us-east-2.amazonaws.com/assets.ethelsclub.com/formnoform/assets/the-passion-of-an-artist/the-passion-of-an-artist.m3u8"
  }
];
/*
const PLAYLISTS = [
  {
    id: 0,
    title: "Haywire Weekend",
    videos: HAYWIRE,
    duration: "33:33",
    durationPretty: "3:30"
  },
  {
    id: 1,
    title: "House Of Yes",
    videos: HOUSE_OF_YES,
    duration: "44:44",
    durationPretty: "4:04"
  }
];*/

const KIAMBER_PLAYLIST = [
  {
    id: 0,
    title: "Who I Am",
    src: "https://kiamber-assets.s3.us-east-2.amazonaws.com/homepage-hero-video-kimaber-com.m3u8",
    duration: 179,
    durationPretty: "2:59"
  }
]

const PLAYLISTS = [
  {
    id: 0,
    title: "Kiamber",
    videos: KIAMBER_PLAYLIST,
    duration: "2:59",
    durationPretty: "2:59"
  }
];

export default class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 1,
      currentlyPlayingSrc: "",
      currentlyPlayingVideoId: 0,
      currentlySelectedPlaylistIndex: 0,
      navVisibility: false,
      update: false
    }
  }

  componentDidMount() {
    let {
      currentlySelectedPlaylistIndex,
      currentlyPlayingVideoId
    } = this.state;
    this.setState({
      currentlyPlayingSrc: PLAYLISTS[currentlySelectedPlaylistIndex].videos[currentlyPlayingVideoId].src
    });
  }

  handleChangeVideo(id) {
    let { currentlySelectedPlaylistIndex } = this.state;
    let selected = PLAYLISTS[currentlySelectedPlaylistIndex].videos.filter((video) => video.id == id);
    this.setState({
      update: true,
      currentlyPlayingSrc: selected[0].src,
      currentlyPlayingVideoId: id
    }, () => {
      this.setState({
        update: false
      })
    });
  }

  handleChangePlaylist(index) {
    this.setState({
      currentlySelectedPlaylistIndex: index,
      currentlyPlayingVideoId: 0,
      currentlyPlayingSrc: PLAYLISTS[index].videos[0].src
    })
  }

  setNavVisibility() {
    this.setState({
      navVisibility: !this.state.navVisibility
    })
  }

  getCurrentlyPlaying() {
    let { currentlySelectedPlaylistIndex, currentlyPlayingVideoId } = this.state;
    let newIndex;

    if (currentlyPlayingVideoId === PLAYLISTS[currentlySelectedPlaylistIndex].videos.length - 1) {
      newIndex = 0
    } else {
      newIndex = currentlyPlayingVideoId + 1;
    }

    this.handleChangeVideo(newIndex);
  }

  render() {
    let {
      currentlyPlayingSrc,
      currentlyPlayingVideoId,
      currentlySelectedPlaylistIndex,
      update
    } = this.state

    let {
      subscribeEmail
    } = this.props;

    if (currentlyPlayingSrc === null) return null;
    return (
      <div className="expt-home-hero-container expt-background-100c23">
        <Video
          update={update}
          onEnded={this.getCurrentlyPlaying.bind(this)}
          src={currentlyPlayingSrc}/>
        <PlaylistNav
          playlists={PLAYLISTS}
          currentlySelectedPlaylistIndex={currentlySelectedPlaylistIndex}
          setNavVisibility={this.setNavVisibility.bind(this)}
          currentlyPlayingVideoId={currentlyPlayingVideoId}
          selectedPlaylist={PLAYLISTS[currentlySelectedPlaylistIndex]}
          onChangeVideo={this.handleChangeVideo.bind(this)}
          onChangePlaylist={this.handleChangePlaylist.bind(this)}
        />
      </div>
    )
  }
}
