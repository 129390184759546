import React, { Component } from 'react';
import './divider.css';

export default class Divider extends Component {
  render() {
    let { className } = this.props;
    return (
      <div className={`${className} ki-divider`}/>
    )
  }
}
