import React, { Component } from 'react';
import Hero from './sections/hero';
import Brandvibes from './sections/brandvibes';
import Info from 'components/info';
import Detail from 'components/detail';
import Divider from 'components/divider';
import RestageDescription from './sections/restage.js'
import ExperienceThisDescription from './sections/experiencethis.js'
import '../about/about.css';

export default class Restage extends Component {
  render() {
    return (
      <div>
        <Hero />
        <div className="ki-background-smokey-black d-flex flex-column align-items-end">
          <div style={{ paddingBottom: '3.125vw' }} className="d-flex flex-md-row flex-column ki-about-top-text-section">
            <div className="ki-about-details">
              <Detail header="Industry" text={()=>{
                return (
                  <>EVENTS, EXPERIENCES,<br/>TECHNOLOGY</>
                )
              }} />
              <Detail header="Location" text="NEW YORK, NY" />
              <Detail header="Website" label="RESTAGE" href="https://www.restagex.com" />
            </div>
            <div className="ki-about-main-section">
              <div className="ki-poppins ki-font-white ki-font-72 ki-font-bold ki-about-headline">EXPERIENCE EVENTS THROUGH PARTNERSHIPS AND VIDEO</div>
              <div className="ki-poppins ki-font-white ki-font-40 ki-about-about">
              Restage is building a market network for events where event hosts can connect with sponsors and share events content with new audiences. Meet our products - Restagex and Experience This. Two tools designed to help you elevate your event experience.               </div>
            </div>
          </div>
          <Brandvibes />
          {/*<div className="ki-about-top-text-section ">
            <ExperienceThisDescription />
            <RestageDescription />
          </div>*/}

          {/*<Info
            header={()=>{
              return(<>MORE<br/>ABOUT ME</>)
            }}
            text="It is no coincidence that I’m in a business function at a tech company. I genuinely enjoy learning about frontier technologies and company building. I am tenacious and curious. I put emphasis on asking great questions and continuously learning. My obsession with learning, growth, and self-reflection is a strength and will help me win now and in the future."/>
        </div>
        <div className="ki-background-smokey-black ki-about-bottom-text-section d-flex flex-column align-items-end">
          <Info
            header={()=>{
              return(<>THE<br/>ROOTS</>)
            }}
            text="I was born and raised in Memphis, TN and went to high school in a small town in Mississippi. My participation in the school’s robotics team gave me an introduction to engineering and computer programming. In college, I was a Resident Advisor for two years and I also served on the Student Staff Leadership Council. I worked other part-time jobs throughout college including two years with an event venue where we hosted over 280 events a year."/>
            <div className="ki-about-divider-wrapper d-flex align-items-start">
              <Divider className="ki-background-purple ki-about-divider" />
            </div>
          <Info
            className="ki-about-info-section"
            header={()=>{
              return(<>THE<br/>GRIND</>)
            }}
            text="I went to Georgia Tech first as a computer science major then switched to the business and finance program in my sophomore year. After college, I worked for Morgan Stanley in the Wealth Management Branch Analyst program where I rotated between service, risk, and business development functions. I also worked closely with Financial Advisors to develop their client acquisition and engagement strategies. I move to New York in 2017 to work on the COO team for Morgan Stanley’s Private Wealth Management business where I led strategic projects and managed day-to-day business activities." />
          <Info
            header={()=>{
              return(<>THE<br/>JOURNEY</>)
            }}
            text="I began working on Restage because I experienced being a novice event organizer wanting to develop a strong theme, hit all the success metrics I had set, and hope everyone was having a great time. I want to be an entrepreneur because I want to have a great impact on the world and because I want to bring ideas to life. I see entrepreneurship as a great avenue for both of those wishes."/>*/}
        </div>
      </div>
    )
  }
}
