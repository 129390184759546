import React, { Component } from 'react';
import KiamberImg from 'assets/kiamber.png';
import './hero.css';

export default class Hero extends Component {
  render() {
    return (
      <div className="ki-about-hero-container">
        <img alt="Picture of Kiamber in front of a gray background" src={KiamberImg} className="ki-about-hero-img"/>
        <div className="ki-about-hero-text d-flex flex-column align-items-end justify-content-end ki-font-white">
          <div className="ki-poppins ki-font-96 ki-font-40-mb ki-about-hero-text-item">ABOUT</div>
          <div className="ki-poppins ki-font-244 ki-font-60-mb ki-about-hero-text-item ki-font-bold">KIAMBER</div>
        </div>
      </div>
    )
  }
}
