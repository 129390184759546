import React, { Component } from 'react';
import './playlistMenu.css';

export default class PlaylistMenu extends Component {
  render() {
    return (
      <div className="expt-playlistMenu-container">

      </div>
    )
  }
}
