import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from 'screens/about/about.screen';
import Home from 'screens/home/home.screen';
import Navbar from 'components/navbar.js';
import Footer from 'components/footer';
import MadSane from 'screens/music/madsane/madsane.screen';
import Restage from 'screens/restage/restage.screen';
import {
  HOME,
  ABOUT,
  MAD_SANE,
  RESTAGE
} from 'constants/routes';
import './App.css';
import './text.css';

//TODO: Add and request design for a 404 page
export default class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Navbar />
          <Route exact path={HOME} component={About} />
          <Route exact path={ABOUT} component={About} />
          {/*<Route exact path={HOME} component={Home} />
          <Route exact path={ABOUT} component={About} />*/}
          <Route exact path={MAD_SANE} component={MadSane} />
          <Route exact path={RESTAGE} component={Restage} />
          <Route path={[ABOUT, MAD_SANE, RESTAGE]} component={Footer} />
        </Router>
      </div>
    )
  }
}
