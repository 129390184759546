import React, { Component } from 'react';
import {
  INSTAGRAM,
  TWITTER,
  YOUTUBE,
  LINKEDIN
} from 'constants/routes';
import './footer.css';
import YOUTUBE_LOGO from 'assets/youtube@3x.png';
import TWITTER_LOGO from 'assets/twitter@3x.png';
import INSTAGRAM_LOGO from 'assets/instagram@3x.png';
import LINKEDIN_LOGO from 'assets/linkedin.svg';
import TextFieldButtonGroup from 'components/textfieldbuttongroup.component';
import './footer.css';
import {
  EMAIL
} from 'constants/routes'

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  onChange() {

  }
  render() {
    return (
      <div className="ki-background-100C23 ki-footer-container">
        <div className="ki-footer-main d-flex align-items-center justify-content-center flex-column">
          <div className="d-flex flex-column align-items-center">
            <div className="ki-poppins ki-font-semibold ki-font-semibold ki-font-28 ki-font-24-mb ki-font-white">Stay Connected with Me</div>
            {/*<div className="ki-font-16 ki-font-16-mb ki-font-white ki-montserrat ki-font-bold">Enter email address</div>
            <TextFieldButtonGroup
              className="ki-footer-textfieldbuttongroup"
              label="SUBSCRIBE VIA EMAIL"
              onClick={(()=>{}).bind(this)} />*/}
            <a style={{marginTop: '1vw', marginBottom: '1vw' }} className="ki-font-white ki-footer-button ki-font-40 ki-background-100C23 ki-poppins ki-font-medium" href={EMAIL}>Contact</a>
            <div className="ki-footer-social-links d-flex justify-content-center">
              {/*}<a href={TWITTER} rel="noopener noreferrer"><img className="ki-footer-social-link" alt="Twitter" src={TWITTER_LOGO} /></a>*/}
              <a href={INSTAGRAM} rel="noopener noreferrer"><img className="ki-footer-social-link" alt="Instagram" src={INSTAGRAM_LOGO} /></a>
              {/*}<a href={YOUTUBE} rel="noopener noreferrer"><img className="ki-footer-social-link" alt="Youtube" src={YOUTUBE_LOGO} /></a>*/}
              <a href={LINKEDIN} rel="noopener noreferrer"><img className="ki-footer-social-link" alt="Linkedin" src={LINKEDIN_LOGO} /></a>
            </div>
          </div>
          <div className="ki-poppins ki-font-16 ki-font-white ki-font-medium">© 2022 Kiamber.com</div>
        </div>
      </div>
    )
  }
}
