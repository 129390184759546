import React, { Component } from 'react';
import PlayButtonIcon from 'assets/play-button-purple-gray@3x.png';
import './playlistItem.css';

export default class PlaylistItem extends Component {
  render() {
    let {
      title,
      playlistTitle,
      playlistDuration,
      onClick
    } = this.props;
    return (
      <div onClick={onClick.bind(this)} className="expt-font-purple-gray expt-font-medium expt-poppins expt-playlist-item-container expt-font-16 d-flex align-items-center justify-content-between">
        <div>
          {/*<div>{title}</div>*/}
          <div className="d-flex">
            <div>{playlistTitle}</div>&nbsp;
            <div>{playlistDuration}</div>
          </div>
        </div>
        <div><img className="expt-playlist-button-icon" src={PlayButtonIcon}/></div>
      </div>
    )
  }
}
