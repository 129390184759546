import React, { Component } from 'react';
import AmberBeer from 'assets/amber-beer@3x.png';
import AmberCafeLaptop from 'assets/amber-cafe-laptop@3x.png';
import AmberCafe from 'assets/amber-cafe@3x.png';
import AmberOrangeBackground from 'assets/amber-orange-background@3x.png';
import AmberSantacon from 'assets/amber-santacon@3x.png';
import AmberShoes from 'assets/amber-shoes@3x.png';
import AmberVisionBoard from 'assets/amber-vision-board@3x.png';
import AmberWhiteCoat from 'assets/amber-white-coat@3x.png';
import AboutBackground from 'assets/about-background@3x.png';
import Headphones from 'assets/headphones@3x.png';
import './again.css';

const IMAGES = [
  {
    src: AmberWhiteCoat,
    className: "ki-whitecoat",
    width_dk: 23.4640625
  },
  {
    src: AmberOrangeBackground,
    className: "ki-orangebackround",
    width_dk: 37.8369791667
  },
  {
    src: AmberCafeLaptop,
    className: "ki-cafe-laptop",
    width_dk: 31.2890625
  },
  {
    src: AmberVisionBoard,
    className: "ki-visionboard",
    width_dk: 55.625
  },
  {
    src: AmberSantacon,
    className: "ki-santacon",
    width_dk: 23.5036458
  },
  {
    src: AmberCafe,
    className: "ki-cafe",
    width_dk: 31.2890625
  },
  {
    src: AmberBeer,
    className: "ki-beer",
    width_dk: 23.5036458
  },
  {
    src: AmberShoes,
    className: "ki-shoes",
    width_dk: 23.5036458
  }
]

export default class Carousel extends Component {
  render () {
    return (
      <div className="ki-about-carousel-container">
        <img src={AboutBackground} style={{position: "absolute", width: '100%', bottom: "0px"}} />
        <img src={Headphones} className="ki-about-headphones" />
        <div className="ki-about-carousel-container-imgs  d-flex flex-row">
          {
            IMAGES.map((image) => {
              const { src, width_dk, className } = image
              return (
                <div>
                  <img src={src} className={`${className} ki-again-img`} />
                </div>
              )
            })
          }
        </div>

      </div>
    )
  }
}
