import React, { Component } from 'react';
import './detail.css';

export default class Details extends Component {
  getTextElement(text) {
    if (text) {
      return (
        <div className="ki-poppins ki-font-white ki-font-40 ki-font-20-mb ki-font-bold ki-details-text">{(typeof(text) === 'function' ? text() : text)}</div>
      )
    }
  }

  getHrefElement(href, label) {
    if (href) {
      return (
        <a className="ki-poppins ki-font-white ki-font-40 ki-font-20-mb ki-font-bold ki-details-text" href={href} target="_blank">{label}</a>
      )
    }
  }
  render() {
    let { header, text, href, label } = this.props;
    return (
      <div className="ki-text-align-end-dk ki-text-align-start-mb">
        <div className="ki-poppins ki-font-white ki-font-40 ki-font-25-mb">{header}</div>
        {this.getHrefElement(href, label)}
        {this.getTextElement(text)}
      </div>
    )
  }
}
