import React, { Component } from 'react'
import TargetWithArrow from 'assets/target-with-arrow.svg'
import Ribbon from 'assets/ribbon@3x.png'
import BrandVibesTop from 'assets/brand-vibes-top.png'
import BrandVibesBottom from 'assets/brand-vibes-bottom.png'
import './brandvibes.css'

export default class Brandvibes extends Component {
  render() {
    return (
      <div className="d-flex flex-column align-items-center ki-brandvibes-container">
        <div className="ki-poppins ki-font-224 ki-font-48-mb ki-font-white ki-font-weight-700 ki-text-align-center ki-brandvibes-header">BRAND VIBES</div>
        <div className="ki-brandvibes-main">
          <div className="ki-text-align-end ki-raustila ki-font-30-mb ki-font-144 ki-font-white">Drippin in Purpose</div>
          <div className="ki-brandvibes-mission">
            <div className="ki-brandvibes-mission-label ki-poppins ki-font-white ki-font-40 ki-font-bold">MISSION</div>
            <div className="ki-poppins ki-font-white ki-font-40 ki-brandvibes-mission-description">
              <div>
                Build technology infrastructure for the experience economy.
              </div>
              <div>
                We see a world where businesses sale transformative experiences to help people.
              </div>
            </div>
          </div>
          <div>
            <div className="ki-poppins ki-font-white ki-font-40 ki-font-bold">VALUES</div>
            <div className="ki-brandvibes-values-subheader" className="ki-text-align-center ki-raustila ki-font-72 ki-font-white">🦾 meet CALLAN 🦾</div>
          </div>
          <div className="d-flex flex-md-row flex-column align-items-md-end justify-content-between">
            <div className="d-flex">
              <div className="ki-brandvibes-values-description ki-poppins ki-font-white ki-font-40">
                <div>CALLAN is an approach</div>
                <div>to work that requires...</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div style={{marginRight: '1.30208vw'}}>
                <div className="ki-poppins ki-font-white ki-font-40 ki-brandvibes-target"><img style={{marginRight: '10px'}} src={TargetWithArrow} alt="Target with arrow in it." />Curiosity</div>
                <div className="ki-poppins ki-font-white ki-font-40 ki-brandvibes-target"><img style={{marginRight: '10px'}} src={TargetWithArrow} alt="Target with arrow in it." />Authenticity</div>
                <div className="ki-poppins ki-font-white ki-font-40 ki-brandvibes-target"><img style={{marginRight: '10px'}} src={TargetWithArrow} alt="Target with arrow in it." />Listening</div>
              </div>
              <div>
                <div className="ki-poppins ki-font-white ki-font-40 ki-brandvibes-target"><img style={{marginRight: '10px'}} src={TargetWithArrow} alt="Target with arrow in it." />Learning</div>
                <div className="ki-poppins ki-font-white ki-font-40 ki-brandvibes-target"><img style={{marginRight: '10px'}} src={TargetWithArrow} alt="Target with arrow in it." />Ambition</div>
                <div className="ki-poppins ki-font-white ki-font-40 ki-brandvibes-target"><img style={{marginRight: '10px'}} src={TargetWithArrow} alt="Target with arrow in it." />Nuance</div>
              </div>
            </div>
          </div>
          <div className="ki-brandvibes-purpose ki-font-30-mb ki-text-align-end ki-font-144 ki-font-white ki-champagne-limousines">Drippin in Purpose</div>
        </div>
        <img className="ki-brandvibes-img-top ki-brandvibes-img" src={BrandVibesTop} alt="" />
        <img className="ki-brandvibes-img-bottom ki-brandvibes-img"src={BrandVibesBottom} alt="" />
        <img className="ki-brandvibes-ribbon" src={Ribbon} alt="Gold ribbon with the word 'Work' on it, in raustila font." />
      </div>
    )
  }
}
