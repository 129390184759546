import React, { Component } from 'react';
import PlayButtonIcon from 'assets/play-button@3x.png';
import PlaylistItem from './playlistItem';
import './playlistButton.css';

export default class PlaylistButton extends Component {
  constructor(props) {
      super(props)
      this.state = {
        menuVisible: false
      }
  }

  toggleMenu() {
    this.setState({
      menuVisible: !this.state.menuVisible
    })
  }

  getMenu() {
    let {
      onChangePlaylist,
      playlists,
      selectedId,
      currentlySelectedPlaylistIndex
    } = this.props;

    let {
      menuVisible
    } = this.state;

    if (!menuVisible) return null;
    return (
      <div className="expt-playlistButton-items">
        {
          playlists.map((item, index)=>{
            let { title, id, durationPretty } = item;
            console.log(currentlySelectedPlaylistIndex, id, "here", playlists)
            if (currentlySelectedPlaylistIndex === id) return null;
            return <PlaylistItem key={index} onClick={onChangePlaylist.bind(this, index)} playlistDuration={durationPretty} playlistTitle={title} title={title}/>
          })
        }
      </div>
    )
  }

  render() {
    let { selectedPlaylist, videoTitle, videoDuration } = this.props;
    let {
      title,
      duration,
      durationPretty
    } = selectedPlaylist
    return(
      <div className="expt-playlistButton-container">
        {this.getMenu()}
        <div onClick={this.toggleMenu.bind(this)} className="expt-playlistButton d-flex align-items-center justify-content-between">
          <div className="expt-font-medium expt-poppins expt-font-brand-gray expt-font-16">
            <div>{title}</div>
            <div className="d-flex">
              <div>{videoTitle}</div>&nbsp;
              <div>{videoDuration}</div>
            </div>
          </div>
          <div><img className="expt-playlist-button-icon" src={PlayButtonIcon}/></div>
        </div>
      </div>
    )
  }

}
