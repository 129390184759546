import React from "react"
import Dot from "components/dot"
import "./dotNav.css"

export default class DotNav extends React.Component{
  changeDot(index) {
    this.props.onChangeVideo(index);
  }
  getDots() {
    let {
      currentlyPlayingVideoId,
      dotsData
    } = this.props;
    console.log("currentlyPlayingVideoId", currentlyPlayingVideoId)
    return (
      <div className="d-flex expt-hero-dotsNav-container">
        {
          dotsData.map((dot, index) => {
                console.log("currentlyPlayingVideoId", currentlyPlayingVideoId, index)
            return (
              <Dot key={index}
                onClick={this.changeDot.bind(this, index)}
                selected={currentlyPlayingVideoId == index}
                index={index}/>
            )
          })
        }
      </div>
    )
}

  render () {
    return this.getDots()
  }
}
