import React, { Component } from 'react';
import Hero from './sections/hero';

export default class Home extends Component {
  render() {
    let { subscribeEmail } = this.props
    return (
      <div>
        <Hero />
      </div>
    )
  }
}
