import React, { Component } from 'react';
import Hero from './sections/hero/hero';
import Banner from './sections/banner/banner';
import Listen from './sections/listen/listen';
import './madsane.css';

export default class MadSane extends Component {
  render() {
    return (
      <div className="ki-madsane-container">
        <Hero />
        <Banner />
        <Listen />
      </div>
    )
  }
}
