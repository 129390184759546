import React, { Component } from 'react';
import LineMenu from './lineMenu.js';
import Menu from './menu.js';
import KiamberLogo from 'assets/kiamber-logo@3x.png';
import { withRouter } from "react-router-dom";
import {
  HOME
} from 'constants/routes';
import './navbar.css';


class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      hoverable: false
    }
  }

  componentDidMount() {
    const { pathname } = this.props.history.location
    /*if (HOME === pathname) {
      this.setState({
        hoverable: true
      })
    }*/
  }

  onClickLineMenu() {
    let that = this;
    this.setState({
      visible: !this.state.visible
    },()=>{
      if (that.state.visible) {
        document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
        return;
      }
      document.getElementsByTagName('html')[0].style.overflowY = 'unset';
    })
  }

  getNavbarClassName() {
    const { hoverable, visible } = this.state;
    let className = "ki-navbar-container d-flex align-items-center justify-content-between"
    if (visible) className = `${className} ki-navbar-container-menu-open`
    if (!hoverable) return `${className} ki-background-smokey-black`
    return `${className} ki-navbar-hoverable`
  }

  render() {
    let { visible } = this.state;
    return (
      <>
        <div className={this.getNavbarClassName()}>
          <a href={HOME}>
            <img src={KiamberLogo} className="ki-navbar-logo"/>
          </a>
          <LineMenu open={visible} onClick={this.onClickLineMenu.bind(this)} />
        </div>
        <Menu visible={visible}/>
      </>
    )
  }
}

export default withRouter(Navbar)
