import React, { Component } from 'react';
import './info.css';

export default class Info extends Component {
  static defaultProps = {
    className: ""
  }
  render() {
    let {
      header,
      text,
      className
    } = this.props;

    return (
      <div className={`d-flex flex-md-row flex-column ${className}`}>
        <div className="ki-poppins ki-font-white ki-font-72 ki-font-25-mb ki-font-bold ki-info-header ki-text-align-end-dk ki-text-align-start-mb">{header()}</div>
        <div className="ki-poppins ki-font-white ki-font-40 ki-font-20-mb ki-info-text ki-text-align-justify-dk">{text}</div>
      </div>
    )
  }
}
