import React, { Component } from 'react'
import RestageXLogo from 'assets/restagex-logo.png'
export default class RestageDescription extends Component {
  render() {
    return (
      <div>
        <img src={RestageXLogo} />
      </div>
    )
  }
}
