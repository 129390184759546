import React, { Component } from 'react';
import './textfieldbuttongroup.css';

export default class TextFieldButtonGroup extends Component {
  static defaultProps = {
    onClick: ()=>{},
    className: "",
    groupLabel: null
  }
  handleChange() {
    let {
      onChange
    } = this.props;
    onChange();
  }

  getGroupLabel() {
    let { groupLabel } = this.props;
    if (groupLabel === null) return null;
    return (
      <div>{groupLabel}</div>
    )
  }

  render(){
    let {
      onClick,
      label,
      className,
      groupLabel
    } = this.props;

    return (
      <div>
        {this.getGroupLabel()}
        <div className={`d-flex ${className}`}>
          <div className="d-flex bamex-group-container align-items-center">
            <input
              className="ki-group-textfield"
              onChange={this.handleChange.bind(this)}
              name="field"/>
            <button
              className="ki-font-8-mb ki-group-button ki-font-14 expt-poppins ki-poppins ki-font-medium ki-font-white"
              onClick={onClick.bind(this)}>{label}</button>
          </div>
        </div>
      </div>
    )
  }
}
