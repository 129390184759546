import React, { Component } from 'react';
import Hero from './sections/hero/hero';
import Grid from './sections/grid/grid';
import Carousel from './sections/carousel/again/again';
import Info from 'components/info';
import Detail from 'components/detail';
import Divider from 'components/divider';
import AmberBeer from 'assets/amber-beer@3x.png';
import AmberCafeLaptop from 'assets/amber-cafe-laptop@3x.png';
import AmberCafe from 'assets/amber-cafe@3x.png';
import AmberOrangeBackground from 'assets/amber-orange-background@3x.png';
import AmberSantacon from 'assets/amber-santacon@3x.png';
import AmberShoes from 'assets/amber-shoes@3x.png';
import AmberVisionBoard from 'assets/amber-vision-board@3x.png';
import AmberWhiteCoat from 'assets/amber-white-coat@3x.png';
import {
  AMARESYMONEG_AT_3X_PNG,
  JALISANICHOLE__AT_3X_PNG,
  JAH2011_AT_3X_PNG,
  CAMILA_DE_SOUZA_AT_3X_PNG,
  NK_MOHAMED_AT_3X_PNG,
  FS_BOLDEN_AT_3X_PNG,
  ANTHONYWARE_AT_3X_PNG,
  JEZZ_CHUNG_AT_3X_PNG,
  ANTWAN_EADY_AT_3X_PNG,
  DEEDOERSCH_AT_3X_PNG,
  DGRIFF123_AT_3X_PNG,
  INKRESIDUE_AT_3X_PNG,
  STREETREFUGEE_AT_3X_PNG,
  RAERENEE731_AT_3X_PNG,
  MIKE718__AT_3X_PNG,
  HISHELLI_AT_3X_PNG,
  ABENAANIM21_AT_3X_PNG,
  JOELAKAMAG_AT_3X_PNG,
  CASEYTURNER_AT_3X_PNG,
  IJASGREEN_AT_3X_PNG,
  POINTING_FINGER_AT_3X_PNG,
  POINTING_FINGER_LEFT_AT_3X_PNG,
  ANDREAKCASTILLO_AT_3X_PNG,
  BLOBITTYBLAH_AT_3X_PNG,
  KENSHIHAYABUSA_AT_3X_PNG,
  ASHLEYHEFNAWY_AT_3X_PNG,
  PURPLE_PAINTBOARD_BLOB_SVG,
  SXNOIR_AT_3X_PNG,
  MULTICOLORHEARTS_RED_FIRST_SVG
} from 'assets';
import './about.css';


let IMAGES = [
  {
    src: AmberWhiteCoat,
    width_dk: 23.4640625
  },
  {
    src: AmberOrangeBackground,
    width_dk: 37.8369791667
  },
  {
    src: AmberCafeLaptop,
    width_dk: 31.2890625
  },
  {
    src: AmberVisionBoard,
    width_dk: 55.625
  },
  {
    src: AmberSantacon,
    width_dk: 23.5036458
  },
  {
    src: AmberCafe,
    width_dk: 31.2890625
  },
  {
    src: AmberBeer,
    width_dk: 23.5036458
  },
  {
    src: AmberShoes,
    width_dk: 23.5036458
  }
]

export default class About extends Component {
  render() {
    return (
      <div>
        <Hero />
        <div className="ki-about-wrapper">
          <div className="ki-background-smokey-black ki-about-top-text-section d-flex flex-column align-items-end">
            <div className="d-flex flex-md-row flex-column">
              <div className="ki-about-details">
                <Detail header="Full Name" text="KIAMBER MISHEL STAPLES" />
                <Detail header="Home Town" text="MEMPHIS, TN" />
                <Detail header="Current City" text="NEW YORK, NY" />
              </div>
              <div className="ki-about-main-section">
                <div className="ki-poppins ki-font-white ki-font-72 ki-font-30-mb ki-font-bold ki-about-headline">ENABLING EXPERIENCES THROUGH TECHNOLOGY AND CULTURE</div>
                <div className="ki-poppins ki-font-white ki-font-40 ki-font-20-mb ki-about-about">I'm the Founder & CEO of a technology startup, Restage, an events marketplace that helps event organizers and sponsors connect with each other and their audiences using recommendations and targeting. I also make music. My debut project, the Mad Sane EP was released in 2020. Previously, I worked in Wealth Management for Morgan Stanley. That was my career after college. I have a Bachelor’s Degree from Georgia Tech in Business Administration, Finance. I’ve also graduated from the Founder Institute LA, a startup preaccelerator program.</div>
              </div>
            </div>
            <div className="ki-about-divider-wrapper d-flex align-items-start">
              <Divider className="ki-background-purple ki-about-divider" />
            </div>
            <Info
              header={()=>{
                return(<>MORE<br/>ABOUT ME</>)
              }}
              text="It is no coincidence that I’m in a business function at a tech company. I genuinely enjoy learning about frontier technologies and company building. I am tenacious and curious. I put emphasis on asking great questions and continuously learning. My obsession with learning, growth, and self-reflection is a strength and will help me win now and in the future."/>
          </div>
          {/* <Carousel homies={IMAGES} /> */}
          <div className="ki-background-smokey-black ki-about-bottom-text-section d-flex flex-column align-items-end">
            <Info
              header={()=>{
                return(<>THE<br/>ROOTS</>)
              }}
              text="I was born and raised in Memphis, TN and went to high school in a small town in Mississippi. My participation in the school’s robotics team gave me an introduction to engineering and computer programming. In college, I was a Resident Advisor for two years and I also served on the Student Staff Leadership Council. I worked other part-time jobs throughout college including two years with an event venue where we hosted over 280 events a year."/>
              <div className="ki-about-divider-wrapper d-flex align-items-start">
                <Divider className="ki-background-purple ki-about-divider" />
              </div>
            <Info
              className="ki-about-info-section"
              header={()=>{
                return(<>THE<br/>GRIND</>)
              }}
              text="I went to Georgia Tech first as a computer science major then switched to the business and finance program in my sophomore year. After college, I worked for Morgan Stanley in the Wealth Management Branch Analyst program where I rotated between service, risk, and business development functions. I also worked closely with Financial Advisors to develop their client acquisition and engagement strategies. I move to New York in 2017 to work on the COO team for Morgan Stanley’s Private Wealth Management business where I led strategic projects and managed day-to-day business activities." />
            <Info
              header={()=>{
                return(<>THE<br/>JOURNEY</>)
              }}
              text="I began working on Restage because I experienced being a novice event organizer wanting to develop a strong theme, hit all the success metrics I had set, and hope everyone was having a great time. I want to be an entrepreneur because I want to have a great impact on the world and because I want to bring ideas to life. I see entrepreneurship as a great avenue for both of those wishes."/>
          </div>
          <Grid />
        </div>
      </div>
    )
  }
}
