import React, { Component } from 'react';
import Sparkles from 'assets/sparkles@3x.png';
import Globe from 'assets/globe@3x.png';
import {
  MAD_SANE_MASTERLINK
} from 'constants/routes';
import './banner.css';

export default class Banner extends Component {
  render() {
    return (
      <div className="ki-madsane-banner-container ki-background-smokey-black d-flex flex-column align-items-center">
        <div className="ki-font-green ki-font-96 ki-font-40-mb ki-poppins ki-text-align-center">
          Mad Sane EP now <br/>
          available on all platforms
        </div>
        <a className="ki-font-24 ki-font-20-mb ki-poppins ki-font-white ki-background-green ki-madsane-banner-link d-flex align-items-center justify-content-center" href={MAD_SANE_MASTERLINK} rel="noopener noreferrer" target="_blank">MASTERLINK</a>
        <img className="ki-madsane-banner-globe-tl" src={Globe} />
        <img className="ki-madsane-banner-sparkles-tl" src={Sparkles} />
        <img className="ki-madsane-banner-globe-br" src={Globe} />
        <img className="ki-madsane-banner-sparkles-br" src={Sparkles} />
      </div>
    )
  }
}
