import React, { Component } from 'react';
import Square from './components/square';
import AMBER1 from 'assets/amber-1@3x.png';
import AMBER2 from 'assets/amber-2@3x.png';
import AMBER3 from 'assets/amber-3@3x.png';
import './components/square';
import './grid.css';

export default class Grid extends Component {
  render() {
    return (
      <div className="d-flex flex-wrap">
        <Square>
          <img className="ki-about-grid-square-amber-img-1" src={AMBER1} />
        </Square>
        <Square className="ki-background-purple">
          <div className="d-flex align-items-center justify-content-center ki-font-100C23 ki-font-72 ki-font-40-mb ki-poppins ki-grid-square ki-font-bold">TECH</div>
        </Square>
        <Square className="ki-background-green">
          <div className="d-flex align-items-center justify-content-center ki-font-100C23 ki-font-72 ki-font-40-mb ki-poppins ki-grid-square ki-font-bold">FINANCE</div>
        </Square>
        <Square className="ki-background-purple">
          <div className="d-flex align-items-center justify-content-center ki-font-100C23 ki-font-72 ki-font-40-mb ki-poppins ki-grid-square ki-font-bold">EVENTS</div>
        </Square>
        <Square className="ki-background-plum">
          <div className="d-flex align-items-center justify-content-center ki-font-100C23 ki-font-72 ki-font-40-mb ki-poppins ki-grid-square ki-font-bold">ART</div>
        </Square>
        <Square>
          <img  className="ki-about-grid-square-amber-img-3" src={AMBER3} />
        </Square>
        <Square className="ki-background-plum">
          <div className="d-flex align-items-center justify-content-center ki-font-100C23 ki-font-72 ki-font-40-mb ki-poppins ki-grid-square ki-font-bold">30 HRS</div>
        </Square>
        <Square>
          <img  className="ki-about-grid-square-amber-img-2" src={AMBER2} />
        </Square>
        <Square className="ki-background-green">
          <div className="d-flex align-items-center justify-content-center ki-font-100C23 ki-font-72 ki-font-40-mb ki-poppins ki-grid-square ki-font-bold">LIFESTYLE</div>
        </Square>
      </div>
    )
  }
}
