export const HOME = "/";
export const ABOUT = "/about";
export const MAD_SANE = "/mad-sane";
export const RESTAGE = "/restage";
export const MAD_SANE_MASTERLINK = "https://untd.io/r/ArcJZRLKzVaA"
export const INSTAGRAM = "https://www.instagram.com/kiamber_/?hl=en";
export const TWITTER = "https://www.instagram.com/kiamber_/?hl=en";
export const YOUTUBE = "https://www.instagram.com/kiamber_/?hl=en";
export const LINKEDIN = "https://www.linkedin.com/in/kiamberstaples"
export const EMAIL = 'mailto:amber@restagex.com'
