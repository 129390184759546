import React, { Component } from 'react';
import Galaxy from './assets/galaxy.png';
import Planet from './assets/planet.png';
import './listen.css';

export default class Listen extends Component {
  render() {
    return (
      <div className="ki-madsane-listen-container ki-background-smokey-black">
        <img src={Galaxy} className="ki-madsane-listen-galaxy-img" />
        <img src={Planet} className="ki-madsane-listen-planet" />
        <div className="ki-madsane-listen-contents d-flex">
          <div>
            <div className="ki-font-224 ki-font-50-mb ki-font-green ki-raustila ki-madsane-listen-cta-message">Listen to the<br/> EP now</div>
          </div>
          <div>
            <div className="ki-madsane-listen-iframe-wrapper">
              <iframe src="https://open.spotify.com/embed/album/25aTwElP1S7am0vVFyjM8p?si=FQCRE0slSuiJXKTqSzl8WA" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
