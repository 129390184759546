import React, { Component } from 'react';
import Bubbles from 'assets/bubbles@3x.png';
import Bubble1 from 'assets/bubble-1@3x.png';
import BubbleGreen from 'assets/bubble-green@3x.png';
import BubblePurple from 'assets/bubble-purple@3x.png';
import BubbleMagenta from 'assets/bubble-magenta@3x.png';
import LineMenuBackground from 'assets/linemenu-background@3x.png';
import {
  ABOUT,
  RESTAGE,
  MAD_SANE
} from 'constants/routes'
import './menu.css';

export default class Menu extends Component {
  getMenuClassName() {
    let { visible } = this.props;
    let className = "ki-menu-container ki-background-smokey-black d-flex flex-column align-items-end justify-content-center"
    if (visible) {
      return className
    }
    else {
      return `${className} ki-menu-hidden`
    }
  }
  render() {
    return (
      <div className={this.getMenuClassName()}>
        <img src={LineMenuBackground} className="ki-menu-background" />
        {/*}<img src={Bubbles} className="ki-menu-bubbles-br" />
        <img src={Bubble1} className="ki-menu-bubble-1-tl" />
        <img src={BubbleGreen} className="ki-menu-bubble-green" />
        <img src={BubblePurple} className="ki-menu-bubble-purple" />
        <img src={BubbleMagenta} className="ki-menu-bubble-magenta"/>*/}
        <div className="ki-menu-links d-flex flex-column">
          {/*<a href={ABOUT} className="ki-roboto ki-font-64 ki-font-40-mb ki-font-white">About</a>*/}
          <a href={RESTAGE} className="ki-roboto ki-font-64 ki-font-40-mb ki-font-white">Restage</a>
          <a href={MAD_SANE} className="ki-roboto ki-font-64 ki-font-40-mb ki-font-white">Mad Sane EP</a>
        </div>
        <div className="ki-poppins ki-font-14 ki-font-white ki-menu-copyright d-flex justify-content-center ki-font-medium">© 2020 KIAMBER.COM</div>
      </div>
    )
  }
}
