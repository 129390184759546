import React, { Component } from 'react';
import CoverImg from './assets/cover@3x.png';
import './hero.css';

export default class Hero extends Component {
  render() {
    return (
      <div className="ki-madsane-hero-container">
        <img src={CoverImg} alt="Illustration of a representation of Kiamber. She has a third eye on her forehad. Her hair is in a pony tail, visible from the front. She is wearing long golden earings, and behind her is a glowing neural network." className="ki-madsane-hero-img" />
        <div className="ki-madsane-hero-text d-flex flex-column align-items-center justify-content-center">
          <div className="ki-font-50-mb ki-font-96 ki-font-white ki-montserrat ki-font-semibold ki-madsane-hero-title">Mad Sane EP</div>
          <div className="d-flex align-items-center">
            <span className="ki-font-64 ki-font-20-mb ki-font-white ki-montserrat">Songs</span>
            <span className="ki-background-green ki-madsane-hero-dot" />
            <span className="ki-font-64 ki-font-20-mb ki-font-white ki-montserrat">Visuals</span>
            <span className="ki-background-green ki-madsane-hero-dot" />
            <span className="ki-font-64 ki-font-20-mb ki-font-white ki-montserrat">Lyrics</span>
          </div>
        </div>
      </div>
    )
  }
}
